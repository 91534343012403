<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Position Name <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mps_name" placeholder="e.g Position Name">
                      </b-form-input>
                      <VValidate name="Position Name" v-model="row.mps_name" :rules="mrValidation.mps_name" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Position Type <span class="text-danger mr5">*</span></label>
                      <v-select id="proCategory" v-model="row.mps_type" :options="mrType" label="label" :reduce="v => v.value" />
                      <VValidate name="Position Type" v-model="row.mps_type" :rules="mrValidation.mps_type" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group class="">
                      <label>Status<span class="text-danger mr5">*</span></label>
                      <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                      <VValidate name="Status" v-model="row.mps_is_active" :rules="mrValidation.mps_status" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrValidation: Object,
      Config: Object,
    },
    data(){
      return {
        mrType: [
          {label: "Staff", value: "S"},
          {label: "Partner", value: "P"}
        ]
      }
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
    }
 }
</script>
